<template>
  <div>
    <div v-if="educations.length">
      <div v-for="(item, index) in educations" :key="index">
        <div class="col-12 mb-4 align-items-center">
          <div
            class="experience-div position-relative"
            :class="{
              'experience-div__error': educationsHasAnEmptyRow.find(
                (e) => e.id == item.id && e.parsing_id == item.parsing_id
              ),
            }"
          >
            <div class="d-flex justify-content-between">
              <div>
                <div class="float-start me-3 experience-numer">
                  {{ index + 1 }}
                </div>
                <div class="experienceText">
                  <div class="dateOfWork">
                    (<span v-if="getStartDate(item) == 'requiredField'">
                      -
                    </span>
                    <span v-else>{{ getStartDate(item) }} </span> -
                    <span v-if="getEndDate(item) == 'requiredField'"> - </span>
                    <span v-else>{{ getEndDate(item) }}</span
                    >)

                    <span
                      class="company-name opacity"
                      v-if="
                        educationsHasAnEmptyRow.find(
                          (e) =>
                            e.id == item.id && e.parsing_id == item.parsing_id
                        )
                      "
                    >
                      <RequiredField text="Invalid data" />
                    </span>
                  </div>
                  <h5>
                    <span v-if="item.university != null">{{
                      item.university
                    }}</span>
                    <span v-if="!item.university"> - </span>
                    <span class="company-name" v-if="item.degree">{{
                      item.degree
                    }}</span>
                  </h5>
                  <p class="mb-0">
                    <span v-if="item.study_field != null">{{
                      item.study_field
                    }}</span>
                    <span v-if="!item.study_field"> - </span>
                  </p>
                </div>

                <div
                  v-if="false"
                  class="d-grid gap-2 d-flex align-items-center absolute-btns"
                >
                  <button
                    type="button"
                    class="btn experience-btn"
                    @click="
                      deleteItem(
                        item.is_parsed_from_cv ? item.parsing_id : item.id
                      )
                    "
                  >
                    <i class="flaticon-trash"></i>
                  </button>
                  <button
                    type="button"
                    class="btn experience-btn"
                    @click="showModal(item)"
                  >
                    <i class="flaticon-edit"></i>
                  </button>
                </div>
              </div>

              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
                style="height: 40px"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showEducationFormModal(item)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="
                    confirmDeleteProject(
                      item.is_parsed_from_cv ? item.parsing_id : item.id
                    )
                  "
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <div class="wizard-timeline" v-if="educations.length && false">
      <div
        class="wizard-timeline__item"
        v-for="education in educations"
        :key="education.id"
      >
        <div
          class="card py-4 px-5 shadow-xs flex-grow-1"
          :class="{
            'wizard-timeline__border': educationsHasAnEmptyRow.find(
              (e) => e.id === education.id
            ),
          }"
        >
          <div class="d-flex justify-content-between">
            <div>
              <div class="grey--text mb-1">
                (<span v-if="education.start_month !== null">
                  {{ getMonthName(education.start_month) }}
                  {{ education.start_year }} -
                </span>
                <span v-else> - - </span>
                <span v-if="education.currently == 1">Present</span>
                <span
                  v-if="
                    education.currently == 0 && education.end_month !== null
                  "
                  >{{ getMonthName(education.end_month) }}
                  {{ education.end_year }}</span
                >
                <span v-else-if="education.currently == 0">-</span>
                )
              </div>
              <h4 class="mb-2">
                <span v-if="education.university">
                  {{ education.university }}
                </span>
                <span
                  class="wizard-timeline__item__highlighted-text font-size-lg grey--text text--darken-1"
                  v-if="education.degree"
                >
                  {{ education.degree }}
                </span>
              </h4>
            </div>
            <div class="ml-4 d-flex align-items-center">
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="
                  educationsHasAnEmptyRow.find((e) => e.id === education.id)
                "
              >
                Required
                <v-avatar right>
                  <v-icon>report_problem</v-icon>
                </v-avatar>
              </v-chip>
              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showEducationFormModal(education)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="confirmDeleteProject(education.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <p class="mb-0" v-if="education.study_field">
            {{ education.study_field }}
          </p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between border-top pt-10">
      <div class="mr-2">
        <button
          @click.prevent="$emit('previousStep')"
          class="btn btn-light-info font-weight-bold text-uppercase px-9 py-4"
          data-wizard-type="action-prev"
        >
          Previous
        </button>
      </div>
      <div>
        <button
          @click.prevent="nextStep()"
          :disabled="educationsHasAnEmptyRow.length !== 0 ? true : false"
          class="btn btn-info font-weight-bold text-uppercase px-9 py-4"
        >
          Next Step
        </button>
      </div>
    </div>

    <EducationFormModal
      :activeEducation="activeEducation"
      :educationHasAnEmptyRow="educationsHasAnEmptyRow"
    >
      <template #default="{ on }">
        <span ref="education-modal" v-on="on"></span>
      </template>
    </EducationFormModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import RequiredField from "@/components/talent-profile/parsing-cv/requiredField.vue";
import EducationFormModal from "@/components/talent-profile/modals/EducationFormModal.vue";
import Swal from "sweetalert2";
import MONTHS from "@/constants/months";
export default {
  name: "Education",
  components: { EducationFormModal, RequiredField },
  props: {
    profile: {
      required: true,
    },
  },
  data() {
    return {
      activeEducation: null,
      educations: [],
    };
  },
  mounted() {
    this.getImportedCv();
  },
  watch: {
    "profile.educations": {
      immediate: true,
      deep: true,
      handler() {
        if (this.activeEducation) {
          this.educations = this.educations.filter(
            (education) =>
              education.parsing_id != this.activeEducation.parsing_id
          );

          let editedEducations = this.profile.educations.find(
            (ed) => ed.parsing_id == this.activeEducation.parsing_id
          );

          this.educations = [editedEducations, ...this.educations];

          /*           this.educations = [
            ...this.profile.educations.filter(ed => ed.parsing_id && ed.id),
            ...this.educations.filter(
              eItem => !this.profile.educations.find(e => e.id == eItem.id)
            )
          ]; */
        }
      },
    },
  },
  computed: {
    educationsHasAnEmptyRow() {
      return this.educations.filter((education) => {
        let educationCheck = { ...education };

        delete educationCheck.degree;

        if (educationCheck.currently) {
          delete educationCheck.end_month;
          delete educationCheck.end_year;
          delete educationCheck.parsing_id;
        } else {
          if (educationCheck.parsing_id == null)
            delete educationCheck.parsing_id;
        }

        const checkValidations = (ed) => {
          const pattern = /^[\x20-\x7E]*$/;

          if (
            ed.university.length > 100 ||
            ed.study_field.length > 100 ||
            (education.degree && education.degree.length > 100) ||
            !pattern.test(ed.university) ||
            !pattern.test(ed.study_field) ||
            (education.degree && !pattern.test(education.degree))
          ) {
            return true;
          } else {
            return false;
          }
        };

        return (
          this.checkProperties(educationCheck) ||
          checkValidations(educationCheck)
        );
      });
    },
  },
  methods: {
    ...mapActions({
      addTalentEducation: "talentProfile/addTalentEducation",
    }),
    async nextStep() {
      await Promise.all(
        this.educations.map(async (education) => {
          if (
            education &&
            !this.profile.educations.find(
              (ed) => ed.parsing_id == education.parsing_id
            )
          ) {
            try {
              let payload = {
                data: education,
                talent_id: this.$route.params.id,
                messageDisplayed: true,
              };

              await this.addTalentEducation(payload);
            } catch (error) {
              console.log(error);
            }
          }
        })
      ).then(async () => {
        /*         try {
          await this.axios
            .post("/api/users/step", {
              current: this.currentStep - 1
            })
            .then(() => {
              let getParsing = JSON.parse(localStorage.getItem("getParsing"));

              getParsing.experiences = false;

              localStorage.setItem("getParsing", JSON.stringify(getParsing));
            });
        } catch (error) {
          console.log(error);
        } */
        this.$emit("nextStep");
      });
    },
    checkProperties(obj) {
      const isEmpty = !Object.values(obj).every(
        (value) => value !== null && value !== ""
      );
      return isEmpty;
    },
    getImportedCv() {
      this.profile.cv.parsed_cv.data.education.map((education) => {
        let newEducation = {
          is_parsed_from_cv: true,
          parsing_id: education?.id,
          university: education?.organization ? education.organization : "",
          study_field: education?.accreditation?.education
            ? education?.accreditation?.education
            : "",
          degree: education?.accreditation?.educationLevel
            ? education?.accreditation?.educationLevel
            : "",

          currently: education?.dates?.isCurrent ? 1 : 0,
          start_year: education?.dates?.startDate
            ? new Date(education?.dates?.startDate).getFullYear()
            : null,
          start_month: education?.dates?.startDate
            ? new Date(education?.dates?.startDate).getMonth() + 1
            : null,
          end_year: education?.dates?.completionDate
            ? new Date(education?.dates?.completionDate).getFullYear()
            : null,
          end_month: education?.dates?.completionDate
            ? new Date(education?.dates?.completionDate).getMonth() + 1
            : null,
        };

        if (
          !this.educations.find(
            (item) => item.parsing_id == newEducation.parsing_id
          )
        ) {
          this.educations.push(newEducation);
        }
      });
    },
    getStringMonth(id) {
      return MONTHS.find((element) => element.id === id)?.name;
    },
    getStartDate(item) {
      if (item.start_month !== null && item.start_year !== null) {
        return this.getStringMonth(item.start_month) + " " + item.start_year;
      } else {
        return "requiredField";
      }
    },
    getEndDate(item) {
      return item.currently
        ? "present"
        : item.end_month == null || item.end_year == null
        ? "requiredField"
        : this.getStringMonth(item.end_month) + " " + item.end_year;
    },
    getMonthName(monthId) {
      return MONTHS.find((month) => month.id === monthId)?.name;
    },
    showEducationFormModal(education) {
      if (education) {
        this.activeEducation = JSON.parse(JSON.stringify(education));
      } else {
        this.activeEducation = null;
      }
      this.$refs["education-modal"].click();
    },
    confirmDeleteProject(educationId) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.activeEducation = null;
          let education = this.educations.find(
            (item) => item.id == educationId || item.parsing_id == educationId
          );

          if (education.is_parsed_from_cv && !education.id) {
            this.educations = this.educations.filter(
              (item) => item.parsing_id !== educationId
            );
          } else {
            if (education.id) {
              this.deleteEducations(education.id);
            } else {
              this.deleteEducations(educationId);
            }
          }
        }
      });
    },
    deleteEducations(educationId) {
      let payload = {
        educationId,
        talent_id: this.$route.params.id,
      };
      this.$store.dispatch("talentProfile/deleteTalentEducation", payload);
      this.educations = this.educations.filter(
        (item) => item.id !== educationId
      );
    },
  },
};
</script>
