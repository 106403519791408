var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.educations.length)?_c('div',_vm._l((_vm.educations),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"col-12 mb-4 align-items-center"},[_c('div',{staticClass:"experience-div position-relative",class:{
            'experience-div__error': _vm.educationsHasAnEmptyRow.find(
              (e) => e.id == item.id && e.parsing_id == item.parsing_id
            ),
          }},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"float-start me-3 experience-numer"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"experienceText"},[_c('div',{staticClass:"dateOfWork"},[_vm._v(" ("),(_vm.getStartDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getStartDate(item))+" ")]),_vm._v(" - "),(_vm.getEndDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getEndDate(item)))]),_vm._v(") "),(
                      _vm.educationsHasAnEmptyRow.find(
                        (e) =>
                          e.id == item.id && e.parsing_id == item.parsing_id
                      )
                    )?_c('span',{staticClass:"company-name opacity"},[_c('RequiredField',{attrs:{"text":"Invalid data"}})],1):_vm._e()]),_c('h5',[(item.university != null)?_c('span',[_vm._v(_vm._s(item.university))]):_vm._e(),(!item.university)?_c('span',[_vm._v(" - ")]):_vm._e(),(item.degree)?_c('span',{staticClass:"company-name"},[_vm._v(_vm._s(item.degree))]):_vm._e()]),_c('p',{staticClass:"mb-0"},[(item.study_field != null)?_c('span',[_vm._v(_vm._s(item.study_field))]):_vm._e(),(!item.study_field)?_c('span',[_vm._v(" - ")]):_vm._e()])]),(false)?_c('div',{staticClass:"d-grid gap-2 d-flex align-items-center absolute-btns"},[_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteItem(
                      item.is_parsed_from_cv ? item.parsing_id : item.id
                    )}}},[_c('i',{staticClass:"flaticon-trash"})]),_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.showModal(item)}}},[_c('i',{staticClass:"flaticon-edit"})])]):_vm._e()]),_c('b-dropdown',{staticStyle:{"height":"40px"},attrs:{"variant":"outline-info","right":"","no-caret":"","toggle-class":"p-2 ml-3","menu-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"flaticon-more px-0"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showEducationFormModal(item)}}},[_vm._v("Edit")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteProject(
                    item.is_parsed_from_cv ? item.parsing_id : item.id
                  )}}},[_vm._v("Delete")])],1)],1)])])])}),0):_c('h6',{staticClass:"text-muted mb-0"},[_vm._v("No Data")]),(_vm.educations.length && false)?_c('div',{staticClass:"wizard-timeline"},_vm._l((_vm.educations),function(education){return _c('div',{key:education.id,staticClass:"wizard-timeline__item"},[_c('div',{staticClass:"card py-4 px-5 shadow-xs flex-grow-1",class:{
          'wizard-timeline__border': _vm.educationsHasAnEmptyRow.find(
            (e) => e.id === education.id
          ),
        }},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"grey--text mb-1"},[_vm._v(" ("),(education.start_month !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.getMonthName(education.start_month))+" "+_vm._s(education.start_year)+" - ")]):_c('span',[_vm._v(" - - ")]),(education.currently == 1)?_c('span',[_vm._v("Present")]):_vm._e(),(
                  education.currently == 0 && education.end_month !== null
                )?_c('span',[_vm._v(_vm._s(_vm.getMonthName(education.end_month))+" "+_vm._s(education.end_year))]):(education.currently == 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(" ) ")]),_c('h4',{staticClass:"mb-2"},[(education.university)?_c('span',[_vm._v(" "+_vm._s(education.university)+" ")]):_vm._e(),(education.degree)?_c('span',{staticClass:"wizard-timeline__item__highlighted-text font-size-lg grey--text text--darken-1"},[_vm._v(" "+_vm._s(education.degree)+" ")]):_vm._e()])]),_c('div',{staticClass:"ml-4 d-flex align-items-center"},[(
                _vm.educationsHasAnEmptyRow.find((e) => e.id === education.id)
              )?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Required "),_c('v-avatar',{attrs:{"right":""}},[_c('v-icon',[_vm._v("report_problem")])],1)],1):_vm._e(),_c('b-dropdown',{attrs:{"variant":"outline-info","right":"","no-caret":"","toggle-class":"p-2 ml-3","menu-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"flaticon-more px-0"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showEducationFormModal(education)}}},[_vm._v("Edit")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteProject(education.id)}}},[_vm._v("Delete")])],1)],1)]),(education.study_field)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(education.study_field)+" ")]):_vm._e()])])}),0):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between border-top pt-10"},[_c('div',{staticClass:"mr-2"},[_c('button',{staticClass:"btn btn-light-info font-weight-bold text-uppercase px-9 py-4",attrs:{"data-wizard-type":"action-prev"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('previousStep')}}},[_vm._v(" Previous ")])]),_c('div',[_c('button',{staticClass:"btn btn-info font-weight-bold text-uppercase px-9 py-4",attrs:{"disabled":_vm.educationsHasAnEmptyRow.length !== 0 ? true : false},on:{"click":function($event){$event.preventDefault();return _vm.nextStep()}}},[_vm._v(" Next Step ")])])]),_c('EducationFormModal',{attrs:{"activeEducation":_vm.activeEducation,"educationHasAnEmptyRow":_vm.educationsHasAnEmptyRow},scopedSlots:_vm._u([{key:"default",fn:function({ on }){return [_c('span',_vm._g({ref:"education-modal"},on))]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }