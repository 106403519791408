<template>
  <div>
    <div v-if="experiances.length">
      <div v-for="(item, index) in experiances" :key="index">
        <div class="col-12 mb-4 align-items-center">
          <div
            class="experience-div position-relative"
            :class="{
              'experience-div__error': experienceHasAnEmptyRow.find(
                (e) => e.id == item.id && e.parsing_id == item.parsing_id
              ),
            }"
          >
            <div class="d-flex justify-content-between">
              <div>
                <div class="float-start me-3 experience-numer">
                  {{ index + 1 }}
                </div>
                <div class="experienceText">
                  <div class="dateOfWork">
                    <span>
                      (<span v-if="getStartDate(item) == 'requiredField'">
                        -
                      </span>
                      <span v-else>{{ getStartDate(item) }} </span> -
                      <span v-if="getEndDate(item) == 'requiredField'">
                        -
                      </span>
                      <span v-else>{{ getEndDate(item) }}</span
                      >)
                    </span>

                    <span
                      class="company-name opacity"
                      v-if="
                        experienceHasAnEmptyRow.find(
                          (e) =>
                            e.id == item.id && e.parsing_id == item.parsing_id
                        )
                      "
                    >
                      <RequiredField text="Invalid data" />
                    </span>
                  </div>
                  <h5>
                    <span v-if="item.title != null"> {{ item.title }}</span>
                    <span v-if="!item.title"> - </span>

                    <span class="company-name" v-if="item.company != null">{{
                      item.company
                    }}</span>
                    <span class="" v-if="!item.company"> - </span>
                  </h5>
                  <p class="mb-0">
                    <span v-if="item.description !== null">{{
                      item.description
                    }}</span>
                    <span v-if="!item.description"> - </span>
                  </p>
                </div>

                <div
                  v-if="false"
                  class="d-grid gap-2 d-flex align-items-center absolute-btns"
                >
                  <button
                    type="button"
                    class="btn experience-btn"
                    @click="
                      deleteItem(
                        item.is_parsed_from_cv ? item.parsing_id : item.id
                      )
                    "
                  >
                    <i class="flaticon-trash"></i>
                  </button>
                  <button
                    type="button"
                    class="btn experience-btn"
                    @click="showModal(item)"
                  >
                    <i class="flaticon-edit"></i>
                  </button>
                </div>
              </div>

              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
                style="height: 40px"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item href="#" @click.prevent="showModal(item)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="
                    deleteItem(
                      item.is_parsed_from_cv ? item.parsing_id : item.id
                    )
                  "
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <div class="wizard-timeline" v-if="experiances.length && false">
      <div
        class="wizard-timeline__item"
        v-for="experience in experiances"
        :key="experience.id"
      >
        <div
          class="card py-4 px-5 shadow-xs flex-grow-1"
          :class="{
            'wizard-timeline__border': experienceHasAnEmptyRow.find(
              (e) => e.id === experience.id
            ),
          }"
        >
          <div class="d-flex justify-content-between">
            <div>
              <div class="grey--text mb-1">
                (<span v-if="experience.start_month !== null">
                  {{ getMonthName(experience.start_month) }}
                  {{ experience.start_year }} -
                </span>
                <span v-else> - - </span>
                <span v-if="experience.currently_work_in == 1">Present</span>
                <span
                  v-if="
                    experience.currently_work_in == 0 &&
                    experience.end_month !== null
                  "
                  >{{ getMonthName(experience.end_month) }}
                  {{ experience.end_year }}</span
                >
                <span v-else-if="experience.currently_work_in == 0">-</span>
                )
              </div>

              <h4 class="mb-2">
                <span v-if="experience.title">{{ experience.title }} </span>
                <span
                  class="wizard-timeline__item__highlighted-text font-size-lg grey--text text--darken-1"
                  v-if="experience.company"
                >
                  {{ experience.company }}
                </span>
              </h4>
            </div>
            <div class="ml-4 d-flex align-items-center">
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="
                  experienceHasAnEmptyRow.find((e) => e.id === experience.id)
                "
              >
                Required
                <v-avatar right>
                  <v-icon>report_problem</v-icon>
                </v-avatar>
              </v-chip>
              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showExperienceFormModal(experience)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="confirmDeleteProject(experience.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <p class="mb-0" v-if="experience.description">
            {{ experience.description }}
          </p>
        </div>
      </div>
    </div>

    <!--begin: Wizard Actions -->
    <div class="d-flex justify-content-between border-top pt-10">
      <div class="mr-2">
        <button
          @click.prevent="$emit('previousStep')"
          class="btn btn-light-info font-weight-bold text-uppercase px-9 py-4"
          data-wizard-type="action-prev"
        >
          Previous
        </button>
      </div>
      <div>
        <button
          @click.prevent="nextStep()"
          :disabled="experienceHasAnEmptyRow.length != 0 ? true : false"
          class="btn btn-info font-weight-bold text-uppercase px-9 py-4"
        >
          Next Step
        </button>
      </div>
    </div>

    <ExperienceFormModal
      :activeExperience="activeExperience"
      :experienceHasAnEmptyRow="experienceHasAnEmptyRow"
    >
      <template #default="{ on }">
        <span ref="experience-modal" v-on="on"></span>
      </template>
    </ExperienceFormModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import RequiredField from "@/components/talent-profile/parsing-cv/requiredField.vue";
import ExperienceFormModal from "@/components/talent-profile/modals/ExperienceFormModal.vue";
import Swal from "sweetalert2";
import MONTHS from "@/constants/months";

export default {
  name: "Experience",
  components: { ExperienceFormModal, RequiredField },
  props: {
    profile: {
      required: true,
    },
  },
  data() {
    return {
      activeExperience: null,
      experiances: [],
    };
  },
  mounted() {
    this.getImportedCv();
  },
  watch: {
    "profile.experiences": {
      immediate: true,
      deep: true,
      handler() {
        if (this.activeExperience) {
          this.experiances = this.experiances.filter(
            (experience) =>
              experience.parsing_id != this.activeExperience.parsing_id
          );

          let editedExperiences = this.profile.experiences.find(
            (ex) => ex.parsing_id == this.activeExperience.parsing_id
          );

          this.experiances = [editedExperiences, ...this.experiances];

          /*  this.experiances = [
            ...this.experiances,
            ...this.profile.experiences
          ].filter(
            eItem =>
              !this.profile.experiences.find(
                e => e.id == eItem.id && !e.parsing_id
              )
          ); */
        }
      },
    },
  },
  computed: {
    experienceHasAnEmptyRow() {
      return this.experiances.filter((experience) => {
        let experienceCheck = { ...experience };

        if (experienceCheck.currently_work_in) {
          delete experienceCheck.end_month;
          delete experienceCheck.end_year;
          delete experienceCheck.parsing_id;
        } else {
          if (experienceCheck.parsing_id == null)
            delete experienceCheck.parsing_id;
        }

        const checkValidations = (ex) => {
          const pattern = /^[\x20-\x7E]*$/;

          if (
            ex.title.length > 100 ||
            ex.company.length > 100 ||
            ex.description.length > 5000 ||
            !pattern.test(ex.title) ||
            !pattern.test(ex.company)
          ) {
            return true;
          } else {
            return false;
          }
        };
        return (
          this.checkProperties(experienceCheck) ||
          checkValidations(experienceCheck)
        );
      });
    },
  },
  methods: {
    ...mapActions({
      addTalentExperience: "talentProfile/addTalentExperience",
    }),
    async nextStep() {
      await Promise.all(
        this.experiances.map(async (experiance) => {
          if (
            experiance &&
            !this.profile.experiences.find(
              (ex) => ex.parsing_id == experiance.parsing_id
            )
          ) {
            try {
              let payload = {
                data: experiance,
                talent_id: this.$route.params.id,
                messageDisplayed: true,
              };

              await this.addTalentExperience(payload);
            } catch (error) {
              console.log(error);
            }
          }
        })
      ).then(async () => {
        /*         try {
          await this.axios
            .post("/api/users/step", {
              current: this.currentStep - 1
            })
            .then(() => {
              let getParsing = JSON.parse(localStorage.getItem("getParsing"));

              getParsing.experiences = false;

              localStorage.setItem("getParsing", JSON.stringify(getParsing));
            });
        } catch (error) {
          console.log(error);
        } */
        this.$emit("nextStep");
      });
    },
    checkProperties(obj) {
      const isEmpty = !Object.values(obj).every(
        (value) => value !== null && value !== ""
      );
      return isEmpty;
    },
    getImportedCv() {
      this.profile.cv.parsed_cv.data.workExperience.map((experiance) => {
        let newExperiance = {
          is_parsed_from_cv: true,
          parsing_id: experiance?.id,
          title: experiance?.jobTitle ? experiance.jobTitle : "",
          company: experiance.organization ? experiance.organization : "",
          description: experiance.jobDescription
            ? experiance.jobDescription
            : "",
          currently_work_in: experiance?.dates?.isCurrent ? 1 : 0,
          start_year: experiance?.dates?.startDate
            ? new Date(experiance?.dates?.startDate).getFullYear()
            : null,
          start_month: experiance?.dates?.startDate
            ? new Date(experiance?.dates?.startDate).getMonth() + 1
            : null,
          end_year: experiance?.dates?.endDate
            ? new Date(experiance?.dates?.endDate).getFullYear()
            : null,
          end_month: experiance?.dates?.endDate
            ? new Date(experiance?.dates?.endDate).getMonth() + 1
            : null,
        };

        if (
          !this.experiances.find(
            (item) => item.parsing_id == newExperiance.parsing_id
          )
        ) {
          this.experiances.push(newExperiance);
        }
      });
    },
    getStringMonth(id) {
      return MONTHS.find((element) => element.id === id)?.name;
    },
    getStartDate(item) {
      if (item.start_month !== null && item.start_year !== null) {
        return this.getStringMonth(item.start_month) + " " + item.start_year;
      } else {
        return "requiredField";
      }
    },
    getEndDate(item) {
      return item.currently_work_in
        ? "present"
        : item.end_month == null || item.end_year == null
        ? "requiredField"
        : this.getStringMonth(item.end_month) + " " + item.end_year;
    },
    getMonthName(monthId) {
      return MONTHS.find((month) => month.id === monthId)?.name;
    },
    showModal(experience) {
      if (experience) {
        this.activeExperience = JSON.parse(JSON.stringify(experience));
      } else {
        this.activeExperience = null;
      }
      this.$refs["experience-modal"].click();
    },
    deleteItem(experienceId) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.activeExperience = null;
          let experience = this.experiances.find(
            (item) => item.id == experienceId || item.parsing_id == experienceId
          );

          if (experience.is_parsed_from_cv && !experience.id) {
            this.experiances = this.experiances.filter(
              (item) => item.parsing_id !== experienceId
            );
          } else {
            if (experience.id) {
              this.deleteExperiance(experience.id);
            } else {
              this.deleteExperiance(experienceId);
            }
          }
        }
      });
    },
    deleteExperiance(experienceId) {
      let payload = {
        experienceId,
        talent_id: this.$route.params.id,
      };
      this.$store.dispatch("talentProfile/deleteTalentExperience", payload);
      this.experiances = this.experiances.filter(
        (item) => item.id !== experienceId
      );
    },
  },
};
</script>

<style lang="scss">
.experience-div {
  border: 1px solid #dfdede;
  border-radius: 10px;
  padding: 20px 30px;
  -webkit-box-shadow: 1px 1px 1px rgb(165 165 166 / 28%);
  box-shadow: #f8f6f6 0px 8px 24px;
}

.experience-div__error {
  border: 1px solid #dd636e;
}

.experience-numer {
  border: 1px solid #ddd;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f6f6f6;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 500;
}

.experienceText {
  padding-right: 100px;
  display: block;
  margin-left: 60px;
}

.dateOfWork {
  color: #a5a5a5;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}

.experienceText p {
  color: #666;
  font-size: 14px;
  font-weight: 300;
}

.experienceText h5 {
  font-size: 17px;
}

.experienceText .dot {
  font-size: 30px;
  opacity: 0.2;
  line-height: 3px;
}

.experienceText .company-name {
  font-size: 15px;
  opacity: 0.4;
  position: relative;
  margin-left: 20px;
  padding-left: 0 !important;
}

.experienceText .company-name::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: rgba(216, 216, 216, 1);
  border-radius: 5px;
  position: absolute;
  left: -10px;
  top: 8px;
}
.dateOfWork {
  display: flex;
  flex-direction: row;
  @media (max-width: 425px) {
    flex-direction: column;
    .company-name.opacity {
      margin-left: 10px;
    }
  }
}
</style>
