<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_parsing"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav border-bottom">
            <div class="wizard-steps p-8 p-lg-10">
              <div
                class="wizard-step cursor-default"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <i class="wizard-icon flaticon-info"></i>
                  <h3 class="wizard-title">Basic info</h3>
                </div>
                <i class="wizard-arrow flaticon2-next"></i>
              </div>
              <div class="wizard-step cursor-default" data-wizard-type="step">
                <div class="wizard-label">
                  <i class="wizard-icon flaticon-profile"></i>
                  <h3 class="wizard-title">Experience</h3>
                </div>
                <i class="wizard-arrow flaticon2-next"></i>
              </div>
              <div class="wizard-step cursor-default" data-wizard-type="step">
                <div class="wizard-label">
                  <i class="wizard-icon flaticon2-open-text-book"></i>
                  <h3 class="wizard-title">Education</h3>
                </div>
                <i class="wizard-arrow flaticon2-next"></i>
              </div>
              <div class="wizard-step cursor-default" data-wizard-type="step">
                <div class="wizard-label">
                  <i class="wizard-icon flaticon-file-2"></i>
                  <h3 class="wizard-title">Certificates</h3>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav-->

          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-7">
              <!--begin: Wizard Form-->
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <BasicInfo
                    :profile="profile"
                    ref="BasicStep"
                    v-if="profile"
                    @nextStep="$refs.NextStep.click()"
                  />
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h3 class="mb-10 font-weight-bold text-dark">
                    Experience List
                  </h3>
                  <Experience
                    :profile="profile"
                    ref="ExperienceStep"
                    v-if="profile"
                    @nextStep="$refs.NextStep.click()"
                    @previousStep="$refs.PreviousStep.click()"
                    :experienceHasAnEmptyRow="[]"
                  />
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h3 class="mb-10 font-weight-bold text-dark">
                    Education List
                  </h3>
                  <Education
                    :profile="profile"
                    v-if="profile"
                    ref="EducationStep"
                    @nextStep="$refs.NextStep.click()"
                    @previousStep="$refs.PreviousStep.click()"
                    :EducationHasAnEmptyRow="[]"
                  />
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h3 class="mb-10 font-weight-bold text-dark">
                    Certificates List
                  </h3>
                  <Certificates
                    ref="CertificatesStep"
                    @nextStep="$refs.NextStep.click()"
                    @previousStep="$refs.PreviousStep.click()"
                    :profile="profile"
                    v-if="profile"
                  />
                </div>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Actions -->
                <div class="d-none justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      ref="PreviousStep"
                      class="btn btn-light-info font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Previous
                    </button>
                  </div>
                  <div>
                    <button
                      @click.prevent="submit"
                      class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-submit"
                    >
                      Submit
                    </button>
                    <button
                      @click.prevent
                      class="d btn btn-info font-weight-bold text-uppercase px-9 py-4"
                      ref="NextStep"
                      data-wizard-type="action-next"
                    >
                      Next Step
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
      <!--end: Wizard-->
    </div>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import BasicInfo from "@/components/talent-profile/parsing-cv/BasicInfo.vue";
import Experience from "@/components/talent-profile/parsing-cv/Experience.vue";
import Education from "@/components/talent-profile/parsing-cv/Education.vue";
import Certificates from "@/components/talent-profile/parsing-cv/Certificates.vue";

export default {
  name: "ParsingCv",

  components: {
    BasicInfo,
    Experience,
    Education,
    Certificates,
  },
  data() {
    return {
      profileId: null,
    };
  },
  created() {
    this.handleGettingProfileData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      {
        title: "Talent Profile",
        route: "/dashboard/talent/" + this.$route.params.id,
      },
      {
        title: "Parsing Cv",
      },
    ]);

    const wizard = new KTWizard("kt_wizard_parsing", {
      startStep: 1, // initial active step number
      clickableSteps: false, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (wizardObj) {
      if (
        wizardObj.currentStep == 2 &&
        this.$refs.ExperienceStep.experienceHasAnEmptyRow.length
      ) {
        wizardObj.stop();
      } else if (
        wizardObj.currentStep == 3 &&
        this.$refs.EducationStep.educationsHasAnEmptyRow.length
      ) {
        wizardObj.stop();
      } else if (
        wizardObj.currentStep == 4 &&
        this.$refs.CertificatesStep.certificatesHasAnEmptyRow.length
      ) {
        wizardObj.stop();
      }
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },

  computed: {
    ...mapGetters({
      profile: "talentProfile/getProfileData",
    }),
  },
  methods: {
    ...mapActions({
      getProfileData: "talentProfile/getProfileData",
    }),
    handleGettingProfileData() {
      if (this.$route.params.id) {
        this.profileId = this.$route.params.id;
        this.getProfileData(this.profileId);
      }
    },
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
  },
};
</script>

<style>
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step {
  cursor: default;
}
</style>
