<template>
  <div>
    <div v-if="certifications.length">
      <div v-for="(item, index) in certifications" :key="index">
        <div class="col-12 mb-4 align-items-center">
          <div
            class="experience-div position-relative"
            :class="{
              'experience-div__error': certificatesHasAnEmptyRow.find(
                (e) => e.name == item.name
              ),
            }"
          >
            <div class="d-flex justify-content-between">
              <div>
                <div class="float-start me-3 experience-numer">
                  {{ index + 1 }}
                </div>
                <div class="experienceText">
                  <div class="dateOfWork">
                    <span
                      v-if="
                        !(
                          getStartDate(item) == 'requiredField' &&
                          getEndDate(item) == 'requiredField'
                        )
                      "
                    >
                      (<span v-if="getStartDate(item) == 'requiredField'">
                        -
                      </span>
                      <span v-else>{{ getStartDate(item) }} </span> -
                      <span v-if="getEndDate(item) == 'requiredField'">
                        -
                      </span>
                      <span v-else>{{ getEndDate(item) }}</span
                      >)
                    </span>

                    <span
                      class="company-name opacity"
                      v-if="
                        certificatesHasAnEmptyRow.find(
                          (e) => e.name == item.name
                        )
                      "
                    >
                      <RequiredField text="Invalid data" />
                    </span>
                  </div>

                  <h5>
                    {{ item.name }}

                    <span class="company-name" v-if="item.organization">{{
                      item.organization
                    }}</span>
                  </h5>
                  <p class="mb-0" v-if="item.identifier">
                    Credential ID {{ item.identifier }}
                  </p>
                  <p>
                    <a
                      class="credential-link"
                      v-if="item.url"
                      :href="getUrl(item.url)"
                      >See credential</a
                    >
                  </p>
                </div>

                <div
                  class="d-grid gap-2 d-flex align-items-center absolute-btns"
                  v-if="false"
                >
                  <button
                    type="button"
                    class="btn experience-btn"
                    @click="
                      item.id
                        ? deleteItem(item.id)
                        : deleteItemByName(item.name)
                    "
                  >
                    <i class="flaticon-trash"></i>
                  </button>
                  <button
                    type="button"
                    class="btn experience-btn"
                    @click="showModal(item)"
                  >
                    <i class="flaticon-edit"></i>
                  </button>
                </div>
              </div>

              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
                style="height: 40px"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showCertificateFormModal(item)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="
                    item.id
                      ? confirmDeleteProject(item.id)
                      : deleteItemByName(item.name)
                  "
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h6 class="text-muted mb-0" v-else>No Data</h6>

    <div class="wizard-timeline" v-if="certifications.length && false">
      <div
        class="wizard-timeline__item"
        v-for="certification in certifications"
        :key="certification.id"
      >
        <div
          class="card py-4 px-5 shadow-xs flex-grow-1"
          :class="{
            'wizard-timeline__border': certificatesHasAnEmptyRow.find(
              (e) => e.name === certification.name
            ),
          }"
        >
          <div class="d-flex justify-content-between">
            <div>
              <div
                class="grey--text mb-1"
                v-if="certification.start_month !== null"
              >
                ({{ getMonthName(certification.start_month) }}
                {{ certification.start_year }} -
                <span v-if="certification.not_expire == 1">Doesn't Expire</span>
                <span v-if="certification.not_expire == 0"
                  >{{ getMonthName(certification.end_month) }}
                  {{ certification.end_year }}</span
                >
                )
              </div>
              <h4 class="mb-2">
                {{ certification.organization }}
              </h4>
              <p class="mb-0 grey--text text--darken-1">
                {{ certification.name }}
              </p>
            </div>
            <div class="ml-4 d-flex align-items-center">
              <b-dropdown
                variant="outline-info"
                right
                no-caret
                toggle-class="p-2 ml-3"
                menu-class="p-0"
              >
                <template #button-content>
                  <i class="flaticon-more px-0"></i>
                </template>
                <b-dropdown-item
                  href="#"
                  @click.prevent="showCertificateFormModal(certification)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click.prevent="confirmDeleteProject(certification.id)"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <p class="mb-0" v-if="certification.identifier">
            <span class="text-dark">Credential I.D :</span>
            {{ certification.identifier }}
          </p>
          <a
            v-if="certification.url"
            :href="getUrl(certification.url)"
            class="text-info text-decoration-underline text-nowrap"
            target="_blank"
            >View Certificate</a
          >
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between border-top pt-10">
      <div class="mr-2">
        <button
          @click.prevent="$emit('previousStep')"
          class="btn btn-light-info font-weight-bold text-uppercase px-9 py-4"
          data-wizard-type="action-prev"
        >
          Previous
        </button>
      </div>
      <div>
        <button
          @click.prevent="nextStep"
          :disabled="certificatesHasAnEmptyRow.length !== 0 ? true : false"
          class="btn btn-info font-weight-bold text-uppercase px-9 py-4"
        >
          Next Step
        </button>
      </div>
    </div>

    <CertificationFormModal
      :activeCertification="activeCertification"
      :certificatesHasAnEmptyRow="certificatesHasAnEmptyRow"
      @editCertification="editCertification"
    >
      <template #default="{ on }">
        <span ref="certification-modal" v-on="on"></span>
      </template>
    </CertificationFormModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RequiredField from "@/components/talent-profile/parsing-cv/requiredField.vue";
import CertificationFormModal from "@/components/talent-profile/modals/CertificationFormModal.vue";
import Swal from "sweetalert2";
import MONTHS from "@/constants/months";
import { getUrl } from "@/helpers";
export default {
  name: "Experience",
  components: { CertificationFormModal, RequiredField },
  props: {
    profile: {
      required: true,
    },
  },
  data() {
    return {
      activeCertification: null,
      certifications: [],
      editCertificationName: null,
    };
  },
  mounted() {
    this.getImportedCv();
  },
  watch: {
    "profile.certifications": {
      immediate: true,
      deep: true,
      handler() {
        if (this.activeCertification) {
          this.certifications = this.certifications.filter(
            (certification) =>
              certification.name != this.activeCertification.name
          );

          let editedCertification = this.profile.certifications.find(
            (cr) => cr.name == this.editCertificationName
          );

          this.certifications = [editedCertification, ...this.certifications];

          this.editCertificationName = null;

          /*this.certifications = [
            ...this.profile.certifications,
            ...this.certifications.filter(
              eItem => !this.profile.certifications.find(e => e.id == eItem.id)
            )
          ]; */
        }
      },
    },
  },
  computed: {
    certificatesHasAnEmptyRow() {
      return this.certifications.filter((certificate) => {
        let certificateCheck = { ...certificate };

        delete certificateCheck.end_month;
        delete certificateCheck.end_year;
        delete certificateCheck.start_month;
        delete certificateCheck.start_year;
        delete certificateCheck.organization;
        if (certificateCheck.not_expire) {
          delete certificateCheck.end_month;
          delete certificateCheck.end_year;
          delete certificateCheck.url;
          delete certificateCheck.identifier;
        } else {
          if (certificateCheck.url == null || !certificateCheck.url) {
            delete certificateCheck.url;
          }
          if (
            certificateCheck.identifier == null ||
            !certificateCheck.identifier
          ) {
            delete certificateCheck.identifier;
          }
        }

        const checkValidations = (c) => {
          const pattern = /^[\x20-\x7E]*$/;

          if (c.name.length > 100 || !pattern.test(certificate.name)) {
            return true;
          } else {
            return false;
          }
        };

        return (
          this.checkProperties(certificateCheck) ||
          checkValidations(certificateCheck)
        );
      });
    },
  },
  methods: {
    ...mapActions({
      addTalentCertification: "talentProfile/addTalentCertification",
    }),
    getUrl,
    async nextStep() {
      await Promise.all(
        this.certifications.map(async (certification) => {
          if (
            certification &&
            !this.profile.certifications.find(
              (ce) => ce.name == certification.name
            )
          ) {
            try {
              let payload = {
                data: certification,
                talent_id: this.$route.params.id,
                messageDisplayed: true,
              };

              await this.addTalentCertification(payload);
            } catch (error) {
              console.log(error);
            }
          }
        })
      ).then(async () => {
        this.$router.push({
          name: "talent-profile",
          params: { id: `${this.$route.params.id}` },
        });
      });
    },
    checkProperties(obj) {
      const isEmpty = !Object.values(obj).every(
        (value) => value !== null && value !== ""
      );
      return isEmpty;
    },
    editCertification(payload) {
      this.editCertificationName = payload;
    },
    getImportedCv() {
      this.profile.cv.parsed_cv.data.certifications.map((certificate) => {
        let newCertificate = {
          name: certificate,
          organization: "",
          not_expire: 0,
          identifier: "",
          url: "",
          start_year: null,
          start_month: null,
          end_year: null,
          end_month: null,
        };

        if (
          !this.certifications.find((item) => item.name == newCertificate.name)
        ) {
          this.certifications.push(newCertificate);
        }
      });
    },
    getMonthName(monthId) {
      return MONTHS.find((month) => month.id === monthId)?.name;
    },
    getStringMonth(id) {
      return MONTHS.find((element) => element.id === id)?.name;
    },
    getStartDate(item) {
      if (item.start_month !== null && item.start_year !== null) {
        return this.getStringMonth(item.start_month) + " " + item.start_year;
      } else {
        return "requiredField";
      }
    },
    getEndDate(item) {
      return item.not_expire
        ? "Doesn’t expire"
        : item.end_month == null || item.end_year == null
        ? "requiredField"
        : this.getStringMonth(item.end_month) + " " + item.end_year;
    },
    showCertificateFormModal(certification) {
      if (certification) {
        this.activeCertification = JSON.parse(JSON.stringify(certification));
      } else {
        this.activeCertification = null;
      }
      this.$refs["certification-modal"].click();
    },
    deleteItemByName(name) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.certifications = this.certifications.filter(
            (certificate) => certificate.name != name
          );
        }
      });
    },
    confirmDeleteProject(certificationId) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCertification(certificationId);
        }
      });
    },
    deleteCertification(certificationId) {
      let payload = {
        certificationId,
        talent_id: this.$route.params.id,
      };
      this.$store.dispatch("talentProfile/deleteTalentCertification", payload);
      this.certifications = this.certifications.filter(
        (item) => item.id !== certificationId
      );
    },
  },
};
</script>
