<template>
  <span class="required-field-wrapper pe-2">
    <img class="me-1" src="@/assets/images/ic_warning.svg" width="18" />
    <span class="required-error mt-1">{{ text }}</span>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Required field",
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.required-field-wrapper {
  .required-error {
    color: rgba(221, 99, 110, 1);
    font-size: 13px;
    font-weight: 500 !important;
  }
}
</style>
