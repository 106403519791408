<template>
  <div>
    <h3 class="mb-10 font-weight-bold text-dark">Languages</h3>
    <div class="formData pt-3">
      <label class="formData__label"
        >What is your First Language? <span class="text-danger">*</span></label
      >
      <div class="row">
        <div class="col-md-6 mb-7">
          <v-select
            :items="lANGUAGES"
            outlined
            dense
            hide-details
            placeholder="Select Language"
            v-model="languagesObj.firstLang"
            :disabled="this.profile.basicInfo.languages[0] ? true : false"
            @blur="$v.languagesObj.firstLang.$touch()"
          ></v-select>
          <VulidateError
            :validationField="$v.languagesObj.firstLang"
            :params="['required']"
          />
        </div>
        <div class="col-md-6 mb-7">
          <v-select
            :items="LEVELS"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            placeholder="Select a Level"
            :disabled="this.profile.basicInfo.languages[0] ? true : false"
            v-model="languagesObj.firstLangLevel"
            @blur="$v.languagesObj.firstLangLevel.$touch()"
          ></v-select>
          <VulidateError
            :validationField="$v.languagesObj.firstLangLevel"
            :params="['required']"
          />
        </div>
      </div>
      <label class="form__label">What is your Second Language? </label>
      <div class="row">
        <div class="col-md-6 mb-7">
          <v-select
            :items="lANGUAGES"
            outlined
            dense
            hide-details
            placeholder="Select Language"
            v-model="languagesObj.secondLang"
            :disabled="this.profile.basicInfo.languages[1] ? true : false"
            clearable
          ></v-select>
          <VulidateError
            :validationField="$v.languagesObj.secondLang"
            :params="['required']"
          />
        </div>
        <div class="col-md-6 mb-7">
          <v-select
            :items="LEVELS"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            placeholder="Select a Level"
            v-model="languagesObj.secondLangLevel"
            :disabled="this.profile.basicInfo.languages[1] ? true : false"
            @blur="$v.languagesObj.secondLangLevel.$touch()"
            clearable
          ></v-select>
          <VulidateError
            :validationField="$v.languagesObj.secondLangLevel"
            :params="['required']"
          />
        </div>
      </div>
    </div>

    <h3 class="mb-10 font-weight-bold text-dark">Experience</h3>
    <div class="row">
      <div class="col-6 mb-7" v-if="isFullStack || isFrontend">
        <v-select
          :items="frontEndLanguages"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          :disabled="profile.basicInfo.FE_lang ? true : false"
          :loading="isLoading.frontEndLanguages"
          v-model="formData.FE_lang"
          @blur="$v.formData.FE_lang.$touch()"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template v-slot:label>
            F.E Language <span class="text-danger">*</span>
          </template></v-select
        >
        <VulidateError
          :validationField="$v.formData.FE_lang"
          :params="['required']"
        />
      </div>
      <div class="col-6 mb-7" v-if="isFullStack || isBackend">
        <v-select
          :items="backEndLanguages"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          :disabled="profile.basicInfo.BE_lang ? true : false"
          :loading="isLoading.backEndLanguages"
          v-model="formData.BE_lang"
          @blur="$v.formData.BE_lang.$touch()"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template v-slot:label>
            B.E Language <span class="text-danger">*</span>
          </template></v-select
        >

        <VulidateError
          :validationField="$v.formData.BE_lang"
          :params="['required']"
        />
      </div>
      <template v-if="isFullStack">
        <div class="col-md-6 mb-7">
          <v-text-field
            outlined
            dense
            hide-details
            v-model="formData.second_experience_years"
            :disabled="profile.basicInfo.second_experience_years ? true : false"
            @blur="$v.formData.second_experience_years.$touch()"
            ><template v-slot:label>
              Years of experience For Frontend
              <span class="text-danger">*</span>
            </template></v-text-field
          >
          <VulidateError
            :validationField="$v.formData.second_experience_years"
            :params="[
              'required',
              'numeric',
              {
                minValue: {
                  number:
                    $v.formData.second_experience_years.$params.minValue.min,
                },
              },
              {
                maxValue: {
                  number:
                    $v.formData.second_experience_years.$params.maxValue.max,
                },
              },
            ]"
          />
        </div>
        <div class="col-md-6 mb-7">
          <v-text-field
            outlined
            dense
            hide-details
            v-model="formData.experience_years"
            :disabled="profile.basicInfo.experience_years ? true : false"
            @blur="$v.formData.experience_years.$touch()"
            ><template v-slot:label>
              Years of experience For Backend
              <span class="text-danger">*</span>
            </template></v-text-field
          >
          <VulidateError
            :validationField="$v.formData.experience_years"
            :params="[
              'required',
              'numeric',
              {
                minValue: {
                  number: $v.formData.experience_years.$params.minValue.min,
                },
              },
              {
                maxValue: {
                  number: $v.formData.experience_years.$params.maxValue.max,
                },
              },
            ]"
          />
        </div>
      </template>
      <div
        class="col-12 mb-7"
        :class="{ 'col-md-6': isBackend || isFrontend }"
        v-else
      >
        <v-text-field
          outlined
          dense
          hide-details
          v-model="formData.experience_years"
          :disabled="profile.basicInfo.experience_years ? true : false"
          @blur="$v.formData.experience_years.$touch()"
        >
          <template v-slot:label>
            Years of experience <span class="text-danger">*</span>
          </template></v-text-field
        >
        <VulidateError
          :validationField="$v.formData.experience_years"
          :params="[
            'required',
            'numeric',
            {
              minValue: {
                number: $v.formData.experience_years.$params.minValue.min,
              },
            },
            {
              maxValue: {
                number: $v.formData.experience_years.$params.maxValue.max,
              },
            },
          ]"
        />
      </div>
    </div>

    <!--     <div
      class="row"
      v-if="
        !profile.basicInfo.experience_years &&
          profile.cv.parsed_cv.data.totalYearsExperience &&
          profile.basicInfo.role &&
          profile.basicInfo.role.name !== 'Frontend' &&
          profile.basicInfo.role.name !== 'Backend' &&
          profile.basicInfo.role.name !== 'Full Stack'
      "
    >
      <div class="col-12 mb-7">
        <label class="form__label"
          >Years of experience <span class="text-danger">*</span></label
        >
        <v-text-field
          outlined
          dense
          hide-details
          v-model="experience_years"
          @blur="$v.experience_years.$touch()"
        >
        </v-text-field>
        <VulidateError
          :validationField="$v.experience_years"
          :params="[
            'required',
            'numeric',
            {
              minValue: {
                number: $v.experience_years.$params.minValue.min
              }
            },
            {
              maxValue: {
                number: $v.experience_years.$params.maxValue.max
              }
            }
          ]"
        />
      </div>
    </div> -->
    <div class="row" v-if="false">
      <div class="col-12 mb-7">
        <h6 class="text-muted mb-0">No Data Or Data Already Exist</h6>
      </div>
    </div>

    <!--begin: Wizard Actions -->
    <div class="d-flex justify-content-end border-top pt-10">
      <div class="mr-2">
        <button
          @click.prevent="$emit('previousStep')"
          class="btn btn-light-info font-weight-bold text-uppercase px-9 py-4"
          data-wizard-type="action-prev"
        >
          Previous
        </button>
      </div>
      <div>
        <button
          @click.prevent="nextStep()"
          class="btn btn-info font-weight-bold text-uppercase px-9 py-4"
        >
          Next Step
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  numeric,
  maxValue,
  minValue,
} from "vuelidate/lib/validators";
import VulidateError from "@/components/shared/vulidateError.vue";
import { lANGUAGES, LEVELS } from "@/constants/languages";
import ACCOUNTS_TYPES from "@/constants/accounts-types";

const initLanguagesObj = {
  firstLang: "",
  firstLangLevel: "",
  secondLang: "",
  secondLangLevel: "",
};

export default {
  name: "BasicInfo",
  mixins: [validationMixin],
  components: {
    VulidateError,
  },
  props: {
    profile: {
      required: true,
    },
  },
  data() {
    return {
      isLoading: {
        roles: false,
        countries: false,
        frontEndLanguages: false,
        backEndLanguages: false,
      },
      dialog: false,
      languagesObj: { ...initLanguagesObj },
      experience_years: null,
      lANGUAGES,
      LEVELS,
      formData: {
        country: null,
        first_name: null,
        last_name: null,
        role_id: null,
        BE_lang: null,
        FE_lang: null,
        experience_years: null,
        second_experience_years: null,
        linkedin: null,
        github: null,
        behance: null,
        dribbble: null,
        websites: [],
      },
      firstLoading: true,
    };
  },
  validations: {
    formData: {
      BE_lang: {
        required: requiredIf(function () {
          return this.isBackend || this.isFullStack;
        }),
      },
      FE_lang: {
        required: requiredIf(function () {
          return this.isFrontend || this.isFullStack;
        }),
      },
      experience_years: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(50),
        numeric,
      },
      second_experience_years: {
        required: requiredIf(function () {
          return this.isFullStack;
        }),
        minValue: minValue(1),
        maxValue: maxValue(50),
        numeric,
      },
    },
    languagesObj: {
      firstLang: {
        required,
      },
      firstLangLevel: {
        required,
      },
      secondLang: {
        required: requiredIf(function () {
          return this.languagesObj.secondLangLevel;
        }),
      },
      secondLangLevel: {
        required: requiredIf(function () {
          return this.languagesObj.secondLang;
        }),
      },
    },
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      roles: "talents/getRoles",
      countryList: "shared/getCountryList",
      frontEndLanguages: "talentProfile/getFrontEndLanguages",
      backEndLanguages: "talentProfile/getBackEndLanguages",
    }),
    isBackend() {
      return this.formData.role_id == 1;
    },
    isFrontend() {
      return this.formData.role_id == 2;
    },
    isFullStack() {
      return this.formData.role_id == 3;
    },
    selectedRole() {
      return this.formData.role_id;
    },
  },
  watch: {
    selectedRole() {
      if (this.isFullStack) {
        this.isLoading.frontEndLanguages = true;
        this.isLoading.backEndLanguages = true;
        this.getFrontEndLanguages().then(
          () => (this.isLoading.frontEndLanguages = false)
        );
        this.getBackEndLanguages().then(
          () => (this.isLoading.backEndLanguages = false)
        );
      } else if (this.isFrontend) {
        this.isLoading.frontEndLanguages = true;
        this.getFrontEndLanguages().then(
          () => (this.isLoading.frontEndLanguages = false)
        );
      } else if (this.isBackend) {
        this.isLoading.backEndLanguages = true;
        this.getBackEndLanguages().then(
          () => (this.isLoading.backEndLanguages = false)
        );
      }
      // reset experience years & tech languages
      /*  if (!this.firstLoading) {
        this.formData.BE_lang = this.formData.FE_lang = this.formData.experience_years = this.formData.second_experience_years = null;
        this.$v.formData.BE_lang.$reset();
        this.$v.formData.FE_lang.$reset();
        this.$v.formData.experience_years.$reset();
        this.$v.formData.second_experience_years.$reset();
      } */
    },
  },
  methods: {
    ...mapActions({
      getBasicInfo: "talentProfile/getBasicInfo",
      getFrontEndLanguages: "talentProfile/getFrontEndLanguages",
      getBackEndLanguages: "talentProfile/getBackEndLanguages",
      getRoles: "talents/getRoles",
      getCountryList: "shared/getCountryList",
    }),
    initData() {
      this.isLoading.roles = true;
      this.isLoading.countries = true;
      this.getRoles().then(() => {
        this.isLoading.roles = false;
      });
      this.getCountryList().then(() => {
        this.isLoading.countries = false;
      });
      if (this.$route.params.id) {
        this.profileId = this.$route.params.id;
        this.getBasicInfo(this.profileId)
          .then((res) => {
            this.formData.first_name = res.first_name;
            this.formData.last_name = res.last_name;
            this.formData.country = res.country;
            this.formData.role_id = res.role?.id || null;
            this.formData.BE_lang = res.BE_lang?.id || null;
            this.formData.FE_lang = res.FE_lang?.id || null;
            this.formData.experience_years =
              this.profile.basicInfo.experience_years;
            this.formData.second_experience_years =
              this.profile.basicInfo.second_experience_years;
            this.$v.$touch();
            res.accounts.forEach((item) => {
              if (item.type !== 5) {
                this.formData[ACCOUNTS_TYPES[item.type]] = item.url;
              } else {
                this.formData.websites.push(item.url);
              }
            });
            if (this.formData.websites.length < 3) {
              for (let i = this.formData.websites.length; i < 3; i++) {
                this.formData.websites[i] = "";
              }
            }
            setTimeout(() => {
              this.$v.$touch();
              this.firstLoading = false;
            }, 20);
          })
          .finally(() => {
            this.initLanguagesData();
          });
      }
    },
    initLanguagesData() {
      if (this.profile.basicInfo.languages[0]) {
        this.languagesObj.firstLang = this.profile.basicInfo.languages[0].name;
        this.languagesObj.firstLangLevel =
          this.profile.basicInfo.languages[0].level;
      }
      if (this.profile.basicInfo.languages[1]) {
        this.languagesObj.secondLang = this.profile.basicInfo.languages[1].name;
        this.languagesObj.secondLangLevel =
          this.profile.basicInfo.languages[1].level;
      }

      if (
        !this.profile.basicInfo.languages.length &&
        this.profile.cv.parsed_cv.data.languages.length
      ) {
        if (this.profile.cv.parsed_cv.data.languages[0]) {
          this.languagesObj.firstLang =
            this.profile.cv.parsed_cv.data.languages[0];
          setTimeout(() => {
            this.$v.$touch();
          }, 20);
        }

        if (this.profile.cv.parsed_cv.data.languages[1]) {
          this.languagesObj.secondLang =
            this.profile.cv.parsed_cv.data.languages[1];
          setTimeout(() => {
            this.$v.$touch();
          }, 30);
        }
      }

      if (!this.profile.basicInfo.experience_years) {
        if (this.profile.cv.parsed_cv.data.totalYearsExperience) {
          this.formData.experience_years =
            this.profile.cv.parsed_cv.data.totalYearsExperience;

          this.formData.second_experience_years =
            this.profile.cv.parsed_cv.data.totalYearsExperience;

          this.$v.$touch();
        }
      }

      this.$v.$touch();
    },
    async nextStep() {
      this.$v.$touch();
      /*  if (this.$v.$anyError) {
        return;
      } */
      let { firstLang, firstLangLevel, secondLang, secondLangLevel } =
        this.languagesObj;
      let languages = [{ name: firstLang, level: firstLangLevel }];
      if (secondLang) {
        languages.push({ name: secondLang, level: secondLangLevel });
      }

      let payloadLanguages = {
        data: { languages },
        talent_id: this.$route.params.id,
      };

      let data = { ...this.formData };
      data.linkedIn = data.linkedin;
      data.gitHub = data.github;
      data.be_lang_id = data.BE_lang;
      data.fe_lang_id = data.FE_lang;
      delete data.linkedin;
      delete data.github;
      delete data.BE_lang;
      delete data.FE_lang;
      data.websites = data.websites.filter((website) => website);
      //data.experience_years = this.experience_years;

      let payloadExperience = {
        id: this.$route.params.id,
        data,
      };

      const editTalentLanguages = this.$store.dispatch(
        "talentProfile/editTalentLanguages",
        payloadLanguages
      );

      const editBasicInfo = this.$store.dispatch(
        "talentProfile/EditBasicInfo",
        payloadExperience
      );

      await Promise.all([editTalentLanguages, editBasicInfo]).then(() => {
        this.$emit("nextStep");
      });
      //
    },
  },
};
</script>
